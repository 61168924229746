import React, { useState } from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Card isIntro={true} />
        <InvestmentPotentialCard />
        <BehavioralSkillsCard />
        <StatusQuoCard />
      </div>
    </div>
  );
}

function Card({ isIntro = false }) {
  return (
    <div className={`card ${isIntro ? 'intro-card' : ''}`}>
      {isIntro ? (
        <div className="intro-content">
          <h1 className="intro-title">How to Get Your Ex Back</h1>
          <p className="intro-quote">Were you <b>pathetic?</b> Did you come off as a low <b>scum loser</b>? Perfect. This guide is for you.</p>
          <p className="intro-text">
            Let’s not sugarcoat it—you messed up. Maybe you begged, maybe you cried, or maybe you just became someone they couldn’t stand to be around anymore. But here’s the thing: No matter how low you feel right now, this isn’t the end. It’s just the beginning.
          </p>
          <p className="intro-text">
            Welcome to your wake-up call. This isn’t about making empty promises or desperate pleas for attention. It’s about turning the tables and making your ex wonder if they made the biggest mistake of their life. This guide isn’t for the faint-hearted or the weak. It’s for those who are ready to confront their own flaws, dig deep, and claw their way back to the top.
          </p>
          <p className="intro-text">
            You’re going to learn how to rebuild yourself from the ground up, not just to get them back, but to make sure they can’t take their eyes off you. You’ll become someone they can’t ignore—someone who exudes confidence, power, and undeniable appeal. And here’s the best part: You’re going to do it on your terms.
          </p>
          <p className="intro-text">
            So, if you’re ready to stop being the pathetic, heartbroken mess and start being the person they can’t help but miss—then read on. But be warned: This isn’t just a guide to getting your ex back. It’s a blueprint for becoming the person you were always meant to be. The kind of person who doesn’t just get their ex back but makes them realize they should have never left in the first place.
          </p>
          <p className="intro-text intro-call-to-action">Are you ready to turn things around? Because it’s time to stop feeling sorry for yourself and start making moves.</p>
        </div>
      ) : (
        <div className="inner-boxes">
          <SmallBox />
          <SmallBox />
          <SmallBox />
          <SmallBox />
        </div>
      )}
    </div>
  );
}

function InvestmentPotentialCard() {
  return (
    <div className="card technique-card">
      <h2 className="technique-title">Investment Potential</h2>
      <div className="inner-boxes">
        <SmallBox title="Highlighting Personal Growth and Development">
          <p>Investment potential in the context of relationships is about making your ex see that you are a worthwhile and growing investment. After a breakup, the first step in showcasing your potential is to emphasize how much you’ve grown. This isn’t just about saying you’ve changed—it’s about demonstrating it through your actions. Whether you’ve taken up new hobbies, advanced in your career, or worked on improving your physical and mental health, these changes should be visible and undeniable. The goal is to make your ex see that you are on an upward trajectory and that investing in you now would be smart because of the growth you’ve already shown.</p>
          <p><strong>Example:</strong> If you’ve started taking a course to further your career or have made strides in personal fitness, subtly showcase these improvements when the opportunity arises. This might mean casually mentioning your latest project at work or how you’ve been feeling great after starting a new fitness routine. The idea is to create a narrative of continuous improvement, where your ex can clearly see that being with you means being part of a positive, forward-moving journey.</p>
          <p><strong>Strategic Absence:</strong> While highlighting your growth is essential, equally important is knowing when to step back. Strategic absence—taking a deliberate break from communication—can amplify the impact of your improvements. When you’re not constantly in their orbit, your ex has the space to reflect on what they’ve lost. This absence makes them more likely to appreciate the growth you’ve undergone, especially when they start to realize they miss the person you’re becoming.</p>
        </SmallBox>

        <SmallBox title="Demonstrating Long-Term Value">
          <p>Once you’ve established that you are continually growing, it’s time to show that you offer long-term value. This is about more than just what you bring to the table now—it’s about the future potential you represent. Talk about your goals, ambitions, and plans in a way that highlights your foresight and planning. Whether it’s a career path, personal development, or even long-term relationship goals, make it clear that you have a vision for the future. This shows your ex that being with you isn’t just about the present but also about the promising future you’re building.</p>
          <p><strong>Example:</strong> If your goal is to start your own business, talk about the steps you’re taking to make that dream a reality. If you’re saving for a house, mention how you’ve been budgeting and planning for it. These discussions should be natural and not forced, showing that you’re someone who thinks ahead and plans for success—traits that are highly attractive when someone is considering the long-term potential of a relationship.</p>
        </SmallBox>

        <SmallBox title="Strategic Reinforcement of Commitment">
          <p>Finally, once you’ve demonstrated growth and long-term value, it’s essential to reinforce your commitment to the relationship subtly. This doesn’t mean pressuring your ex into getting back together immediately. Instead, focus on showing that you’re someone who values commitment and is willing to invest in a relationship that has potential. This could be through small, thoughtful gestures that remind your ex of your reliability and dedication, or by being there in times of need without expecting anything in return. The goal is to subtly communicate that you are ready to invest in them, should they choose to reciprocate.</p>
          <p><strong>Example:</strong> If your ex is going through a tough time, offer support in a way that shows you’re reliable and caring. Perhaps it’s a small favor, a listening ear, or a gesture that shows you still care. These actions, when done without expectation, can make your ex realize that you’re still someone worth investing in because you are committed to their well-being, even when you’re not officially together.</p>
          <p><strong>Strategic Absence (Revisited):</strong> After demonstrating your commitment and value, it’s crucial to step back once again. This strategic absence allows your ex to process the changes they’ve seen in you and to appreciate the renewed potential of being with you. It’s in these moments of absence that your ex may start to miss the security, growth, and potential you represent, making them more likely to seek a renewed connection.</p>
        </SmallBox>
      </div>
    </div>
  );
}

function BehavioralSkillsCard() {
  return (
    <div className="card technique-card">
      <h2 className="technique-title">Behavioral Skills</h2>
      <div className="inner-boxes">
        <SmallBox title="Regaining Respect through Firm Boundaries">
          <p>Respect is the foundation of any relationship, and regaining it after a breakup is crucial if you want to rekindle a connection. You have one chance to make this happen, so it’s vital that your actions are deliberate and confident. The first step in regaining respect is establishing firm boundaries. This means being clear about what you will and will not tolerate. If your ex tries to test these boundaries, it’s imperative that you hold your ground. This isn’t about being cold or harsh; it’s about showing that you value yourself enough to demand respect. When you set and enforce boundaries, you send a strong message that you won’t be walked over, which can reignite their respect and interest.</p>
          <p><strong>Example:</strong> If your ex reaches out in a way that’s disrespectful or dismissive, respond in a manner that reinforces your boundaries. Politely but firmly let them know that you won’t engage in any communication unless it’s respectful and meaningful. This approach shows that you have grown stronger and that you value yourself more than you did before.</p>
        </SmallBox>

        <SmallBox title="Demonstrating Low Value of the Past">
          <p>Once you’ve set your boundaries, it’s time to demonstrate that your ex no longer holds the same value in your life as they once did. This doesn’t mean being cruel or vindictive, but rather showing through your actions that you’ve moved on and are thriving without them. It’s essential to convey that their presence—or absence—doesn’t dictate your happiness. By living your life to the fullest and pursuing new opportunities, you make it clear that they are no longer the center of your universe. This can create a sense of loss or longing in them as they realize that they no longer have the power they once did.</p>
          <p><strong>Example:</strong> Engage in activities that bring you joy and fulfillment, and let your ex see this through social media or mutual friends. Whether it’s traveling, advancing in your career, or spending time with new people, these actions should subtly communicate that your life is full and vibrant without them. This makes them see you in a new light—one where they are no longer the priority, which can shift the power dynamic in your favor.</p>
        </SmallBox>

        <SmallBox title="Reinforcing Respect with Consistent Behavior">
          <p>Regaining respect is only half the battle; maintaining it is where the real challenge lies. To hold onto this newfound respect, your behavior must be consistent and unwavering. Any sign of desperation or neediness can undo the progress you’ve made. It’s crucial to stay true to the boundaries you’ve set and the new standard of self-respect you’ve established. This means continuing to prioritize your well-being and refusing to settle for anything less than what you deserve. The moment your ex sees that you’re consistent in your actions and attitudes, they’ll realize that you’ve changed—and that change is here to stay.</p>
          <p><strong>Example:</strong> If your ex tries to reconnect in a way that feels like they’re testing your boundaries, stand firm. Don’t immediately jump at the chance to get back together. Instead, take your time and assess whether they are now meeting your standards. By doing this, you reinforce the respect they’ve started to regain for you, ensuring that they see you as someone who commands respect, rather than someone who is easily swayed.</p>
        </SmallBox>
      </div>
    </div>
  );
}

function StatusQuoCard() {
  return (
    <div className="card technique-card">
      <h2 className="technique-title">Maintaining the Status Quo / Transitioning Back into Dating</h2>
      <div className="inner-boxes">
        <SmallBox title="Keep Things Light and Casual">
          <p>After you’ve reestablished contact and are on good terms again, it’s important to maintain a sense of lightness and ease in your interactions. Avoid heavy conversations about the past or future too soon, and instead, focus on enjoying each other’s company. The goal is to keep the atmosphere positive and free from pressure, allowing both of you to reconnect naturally. By keeping things light, you give your ex the space to relax and rediscover the reasons they were attracted to you in the first place. It’s about building comfort and rapport without rushing into anything serious.</p>
          <p><strong>Example:</strong> Suggest casual outings like grabbing a coffee, going for a walk, or attending events you both enjoy. Keep the tone of your conversations fun and engaging, steering clear of topics that could stir up old conflicts or pressure the relationship into progressing too quickly.</p>
        </SmallBox>

        <SmallBox title="Reinforce Positive Interactions">
          <p>As you continue to interact, focus on reinforcing the positive aspects of your relationship. Highlight the things that make being together enjoyable—whether it’s shared interests, a mutual sense of humor, or simply the comfort of being around each other. Reinforcing these positive interactions helps to rebuild the emotional connection and reminds your ex why they enjoyed spending time with you in the first place. It’s crucial to be attentive and responsive, making sure that your time together feels rewarding and uplifting.</p>
          <p><strong>Example:</strong> If your ex laughs at a joke or reminisces fondly about something you did together, acknowledge it and build on that moment. Reinforce the positive emotions by creating new, similarly enjoyable experiences that add to the shared history you’re rebuilding.</p>
        </SmallBox>

        <SmallBox title="Gradually Introduce the Idea of Dating Again">
          <p>Once you’ve maintained a steady and positive rapport, it’s time to gently introduce the idea of dating again. This should be done gradually, without any pressure. Start by increasing the frequency and intimacy of your interactions—more one-on-one time, deeper conversations, and subtle gestures of affection. The key is to make the transition feel natural and mutual, rather than forced. By gradually introducing the idea of dating, you allow your ex to become comfortable with the thought of being together again, without feeling rushed or overwhelmed.</p>
          <p><strong>Example:</strong> If things are going well, you might casually suggest doing something that could be considered a date, like dinner at a nice restaurant or attending a more intimate event. Frame it in a way that feels spontaneous and low-pressure, so it doesn’t seem like a major relationship milestone but rather a natural progression of your time together.</p>
        </SmallBox>
      </div>
    </div>
  );
}

function SmallBox({ title, children }) {
  const [expanded, setExpanded] = useState(false);
  const [closing, setClosing] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      setExpanded(false);
    }, 500); // Duration should match the CSS transition time
  };

  return (
    <div
      className={`small-box ${expanded ? (closing ? 'closing' : 'expanded') : ''}`}
      onClick={!expanded ? handleExpand : undefined}
    >
      {expanded && (
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
      )}
      {!expanded ? (
        <div>
          <h3 className="small-box-title">{title}</h3>
          
        
        </div>
      ) : (
        <div className="expanded-content">
          <h3 className="small-box-title">{title}</h3>
          {children}
        </div>
      )}
    </div>
  );
}

export default App;
